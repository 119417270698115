<template>
    <!--     content -->
    <div class="blog-detail-wrapper" v-if="satisfaction">
        <b-row>
            <!-- blogs -->
            <b-col cols="12">
                <b-card no-body class="overflow-hidden">
                    <swiper
                        class="swiper-navigations col-12"
                        :options="swiperOptions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    >
                        <swiper-slide :key="image.path" v-for="image in satisfaction.files">
                            <b-img
                                class="card-img-top"
                                :src="image.full_path"
                                fluid
                                v-if="image.media_type === 'image'"
                            />
                            <video controls class="card-img-top" v-if="image.media_type === 'video'">
                                <source :src="image.full_path" type="video/mp4">
                                {{ $t("Your browser does not support video.") }}
                            </video>
                            <audio controls class="card-img-top" v-if="image.media_type === 'audio'">
                                <source :src="image.full_path" type="audio/wav">
                                <source :src="image.full_path" type="audio/mp3">
                                {{ $t('Your browser does not support audio.') }}
                            </audio>
                        </swiper-slide>

                        <!-- Add Arrows -->
                        <div
                            slot="button-next"
                            class="swiper-button-next" v-show="satisfaction.files>1"
                        />
                        <div
                            slot="button-prev"
                            class="swiper-button-prev" v-show="satisfaction.files>1"
                        />
                        <div
                            slot="pagination"
                            class="swiper-pagination" v-show="satisfaction.files>1"
                        />
                    </swiper>
                    <b-card-body>
                        <b-card-title>
                            <div
                                class="blog-title-truncate text-body-heading">
                                <!--                                {{ complaint.ilce.name }}, {{ complaint.il.name }}-->
                                {{ satisfaction.title ? satisfaction.title : "Memnuniyet başlığı" }}
                            </div>
                        </b-card-title>
                        <b-media no-body>
                            <b-media-aside
                                vertical-align="center"
                                class="mr-50"
                            >
                                <b-avatar
                                    size="24"
                                    :src="satisfaction.user.profile_image"
                                />
                            </b-media-aside>
                            <b-media-body>
                                <small class="text-muted mr-50">by</small>
                                <small>
                                    <span class="text-body">
                                        {{ satisfaction.user.first_name }} {{ satisfaction.user.last_name }}
                                    </span>
                                    <!--                                    <b-link class="text-body">-->
                                    <!--                                        {{ complaint.user.first_name }} {{complaint.user.last_name }}-->
                                    <!--                                    </b-link>-->
                                </small>
                                <span class="text-muted ml-75 mr-50">|</span>
                                <small class="text-muted">{{ satisfaction.created_at }}</small>
                            </b-media-body>
                        </b-media>
                        <div
                            class="blog-content mt-2"
                        >
                            {{ satisfaction.description }}
                        </div>
                        <hr class="my-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <b-button
                                variant="info"
                                :href="'https://www.google.com/maps/search/?api=1&query='+satisfaction.lat+','+satisfaction.long"
                                target="_blank"
                            >
                                <div class="d-flex align-items-center text-body">
                                    <feather-icon
                                        icon="MapPinIcon"
                                        class="mr-25  text-white"
                                    />
                                    <span class="font-weight-bold  text-white">{{ $t('Location') }}</span>
                                </div>
                            </b-button>
                            <b-button variant="success" :to="{ name: 'chat-page', params: { id:  satisfaction.id} }"
                                      class="font-weight-bold">
                                <div class="d-flex align-items-center text-body">
                                    <feather-icon
                                        icon="SendIcon"
                                        class="mr-50 text-white"
                                    />
                                    <span class="font-weight-bold text-white">{{ $t('Go to chat') }}</span>
                                </div>
                            </b-button>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
            <!--/ blogs -->

        </b-row>
        <!--/ blogs -->
    </div>
    <!--/ content -->
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
    BAvatar, BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BCol, BFormGroup,
    BFormInput, BImg, BInputGroup, BInputGroupAppend, BLink,
    BMedia,
    BMediaAside, BMediaBody, BPagination,
    BRow
} from "bootstrap-vue";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "show",
    directives: {
        Ripple,
    },
    components: {
        BButton,
        BRow,
        BCol,
        BCard,
        BFormInput,
        BCardText,
        BCardBody,
        BCardTitle,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BLink,
        BBadge,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BPagination,
        Swiper,
        SwiperSlide,
        ContentWithSidebar,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            swiperOptions: {
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    // when window width is >= 640px
                    767: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    }
                },
                centeredSlides: true,
                loop: false,
                initialSlide: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        }
    },
    computed: {
        satisfaction() {
            return this.$store.state.satisfaction.singleSatisfaction;
        }
    },
    methods: {},
    created() {
        this.$store.dispatch("satisfaction/getSingleSatisfaction", this.$route.params.id)
            .then((res) => {
            })
            .catch((err) => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Error'),
                            icon: 'InfoIcon',
                            text: err.response.data.message ?? this.$t('Something went wrong'),
                            variant: 'danger',
                        },
                    },
                    {
                        position: 'bottom-right',
                    })
            });
    }
}
</script>

<style scoped>

</style>
